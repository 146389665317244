import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
    18,
    'BNB',
    'Binance Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
    18,
    'Binance',
    'Binance Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    18,
    'BUSD',
    'WDEL USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    18,
    'BUSD',
    'WDEL USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0x1c5d8992da64c8d56ea413dd6f723061c29a7c0b', 18, 'WDEL', 'Wrapped WDEL')
export const DAI = new Token(ChainId.MAINNET, '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D', 6, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
  18,
  'ETH',
  'WDEL-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x765277EebeCA2e31912C9946eAe1021199B39C61',
  6,
  'USDC',
  'WDEL-Peg USD Coin',
)

const tokens = {
  wdel: {
    symbol: 'WDEL',
    projectLink: 'https://alveychain.com/',
  },
  del: {
    symbol: 'DEL',
    address: {
      75: '0x1c5d8992da64c8d56ea413dd6f723061c29a7c0b',
      64668: '0x1c5d8992da64c8d56ea413dd6f723061c29a7c0b',
    },
    decimals: 18,
    projectLink: 'https://alveychain.com/',
  },
  
    dusd: {
    symbol: 'DUSD',
    address: {
      75: '0x332a38776d26d44d0c0174992f88224d00e245dd',
      64668: '0x332a38776d26d44d0c0174992f88224d00e245dd',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  
  ample: {
    symbol: 'AMPLE',
    address: {
      64668: '0x335f6e0e804b70a96bf9eb8af31588942e9b2515',
      75: '0x335f6e0e804b70a96bf9eb8af31588942e9b2515',
    },
    decimals: 18,
    projectLink: 'https://ampleswap.com/',
  },
   wbnb: {
    symbol: 'wBNB',
    address: {
      75: '0x1c5d8992da64c8d56ea413dd6f723061c29a7c0b',
      64668: '0x1c5d8992da64c8d56ea413dd6f723061c29a7c0b',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
    busd: {
    symbol: 'BUSD',
    address: {
      75: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
      64668: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      75: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      64668: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  
   syrup: {
    symbol: 'SYRUP',
    address: {
      75: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
      64668: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
   usdt: {
    symbol: 'USDT',
    address: {
      75: '0x55d398326f99059fF775485246999027B3197955',
      64668: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
   qsd: {
    symbol: 'QSD',
    address: {
      75: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
      64668: '',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  bondly: {
    symbol: 'BONDLY',
    address: {
      75: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
      64668: '',
    },
    decimals: 18,
    projectLink: 'https://www.bondly.finance/',
  },
    safemoon: {
    symbol: 'SAFEMOON',
    address: {
      75: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
      64668: '',
    },
    decimals: 9,
    projectLink: 'https://safemoon.net/',
  },
   cake: {
    symbol: 'CAKE',
    address: {
      75: '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
      64668: '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  
}

export default tokens
