import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
 
  
    {
		label: t('DEL'),
		icon: 'Link1Icon',
		
		items: [
			{
			label: t('BSC'),
			href: 'https://ampleswap.com',
			},
			
			{
			label: t('AlveyChain'),
			href: 'https://alvey.ampleswap.com/',
			},
			
			
		],},
  
   {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0x332a38776d26d44d0c0174992f88224d00e245dd',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
  
  /* {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, */
  
  {
    label: t('Docs'),
    icon: 'DocIcon',
    href: 'https://docs.ampleswap.com',
  },
  


		
		
		{
		label: t('Listing'),
		icon: 'Link2Icon',
		
		items: [
			{
			label: t('CoinMarketCap'),
			href: 'https://coinmarketcap.com/currencies/ampleswap-new/',
			},
			{
			label: t('CoinGecko'),
			href: 'https://www.coingecko.com/en/coins/ampleswap',
			},
			{
			label: t('DappRadar'),
			href: 'https://dappradar.com/binance-smart-chain/exchanges/ampleswap',
			},
			{
			label: t('Binance'),
			href: 'https://www.binance.com/en-IN/price/ampleswap-new',
			},
			{
			label: t('CoinBase'),
			href: 'https://www.coinbase.com/price/ampleswap-new',
			},
			{
			label: t('DexGuru'),
			href: 'https://dex.guru/token/bsc/0x19857937848c02afbde8b526610f0f2f89e9960d',
			},
			{
			label: t('BscScan'),
			href: 'https://www.bscscan.com/token/0x19857937848c02afbde8b526610f0f2f89e9960d',
			},
			
		],},
		
		{
		label: t('More'),
		icon: 'MoreIcon',
		
		items: [
				{
				label: t('Blog'),
				href: 'https://ampleswap.medium.com',
				},

				{
				label: t('Github'),
				href: 'https://github.com/ampleswap',
				},
				
  ],},
		
		
		
		
		
  
  
 
        



]

export default config
