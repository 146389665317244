import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   */
  
	{
    pid: 0,
    lpSymbol: 'AMPLE',
    lpAddresses: {
      64668: '0x2a9718deff471f3bb91fa0eceab14154f150a385',
      75: '0x2a9718deff471f3bb91fa0eceab14154f150a385',
    },
    token: tokens.syrup,
    quoteToken: tokens.wdel,
  },
 
  {
    pid: 1,
    lpSymbol: 'AMPLE-BNB LP',
    lpAddresses: {
      64668: '',
      75: '0x0054b548f7ae09d65a79bff3e5cf35da572f257e',
    },
    token: tokens.ample,
    quoteToken: tokens.wdel,
  },
  
 
    
  	  

]

export default farms
